import { browser } from '$app/environment';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (browser) {
  if (window.location.href.includes('tracker.pretpark.club')) {
    Sentry.init({
      dsn: 'https://fcf7ac011818f64a12a87dbb68f6836e@o4506995756040192.ingest.us.sentry.io/4506995766525952',
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      // If you don't want to use Session Replay, just remove the line below:
      integrations: [replayIntegration()]
    });
  }
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
